<template>
    <router-view></router-view>
    <h1>Master Unit</h1>
    <hr />
    <bs-error-message :errors="errorMessages" />
    
    <div class="row justify-content-between">
        <div class="col-md-6">
            <div class="d-grid gap-3 d-md-block">
                <router-link to="/master-unit/create" class="btn btn-primary btn-sm me-md-1">
                    <i class="bi bi-file-earmark"></i> Create
                </router-link>

            </div>
        </div>
        <div class="col-md-6">
            <div class="pagination-right">
                <bs-pagination :pageNumber="pageNumber" :perPage="perPage" :totalPage="totalPage" :totalRow="totalRow" @toPage="toPage" />
            </div>
        </div>
    </div>

    <div class="">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th rowspan="2">
                        Action
                    </th>
                    <th>
                        <bs-ordering property="code" @reorder="reorder">Code</bs-ordering>
                    </th>
                    <th>
                        <bs-ordering property="name" @reorder="reorder">Description</bs-ordering>
                    </th>
                    <th>
                        <bs-ordering property="name" @reorder="reorder">Is Default Unit</bs-ordering>
                    </th>
                    
                </tr>
                <tr>
                    <th>
                        <div>
                            <bs-textbox placeholder="Code" v-model="filter.codeContains" @keyup="search()" />
                        </div>
                    </th>
                    <th>
                        <div>
                            <bs-textbox placeholder="Description" v-model="filter.descriptionContains" @keyup="search()" />
                        </div>
                    </th>
                    <th>
                        <div>
<!--                            <bs-checkbox v-model="filter.isDefaultEqualsBool" @change="search()"  />-->
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-if="data != null && data.length > 0">
                    <tr v-for="item in data" :key="item.id">
                        <td>
                            <div class="btn-group">
                                <button class="btn btn-sm btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="bi bi-menu-down"></i> Action
                                </button>
                                <ul class="dropdown-menu">
                                    <li>
                                        <button @click="detailItem(item)" class="dropdown-item"><i class="bi bi-eye"></i> View Details</button>
                                    </li>
                                    <li>
                                        <button @click="editItem(item)" class="dropdown-item"><i class="bi bi-pencil-square"></i> Edit</button>
                                    </li>
                                    <!--<li>
                                        <button @click="deleteItem(item)" class="dropdown-item"><i class="bi bi-trash"></i> Delete</button>
                                    </li>-->
                                    <li>
                                        <button @click="openSetDefaultPopup(item)" class="dropdown-item"><i class="bi bi-gear-fill"></i> Set as Default</button>
                                    </li>
                                </ul>
                            </div>
                        </td>
                        <td>
                            {{item.code}}
                        </td>

                        <td>
                            {{item.description}}
                        </td>

                        <td>
                            <bs-checkbox v-model="item.isDefault" disabled="disabled"/>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>

    <!-- SET DEFAULT CONFIRMATION POP UP-->
    <bs-popup id="set-default-popup" :auto-show="false" ref="setDefaultPopup">
        <template v-slot:title>
            Disclaimer
        </template>
        <template v-slot:content>
            <a>Are you sure you want to set "${{popupModel.description}}" as default unit?</a>
            <a>Warning: The old default unit will be removed.</a>
        </template>
        <template v-slot:footer>
            <button class="btn btn-sm btn-primary" @click="setDefaultItem()"><i class="bi bi-check-circle"></i> Set as Default</button>
            <button class="btn btn-sm btn-secondary" @click="closeSetDefaultPopup"><i class="bi bi-x-circle"></i> Cancel</button>
        </template>
    </bs-popup>

</template>

<script>
import UnitService from "@/services/unit-service.js";
import BsCheckbox from "@/plugins/components/BsCheckbox.vue";
import {readonly} from "vue";
export default {
    components: {BsCheckbox},
    data: function () {
        return {
            filter: {
                codeContains: '',
                descriptionContains: '',
                page: 1,
                perPage: 10,
                orderByProperty: 'id',
                orderType: 0
            },
            popupModel: {
                id: '',
                code: '',
                description: '',
                isDefault: '',
            },
            errorMessages: null,
            pageNumber: 1,
            perPage: 10,
            totalPage: 100,
            totalRow: 0,
            data: Array()
        }
    },
    watch: {
        $route: function() {
            if (this.$route.path == "/master-unit") {
                this.loadData();
            }
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        readonly,
        async toPage(pageNumber) {
            this.filter.page = pageNumber;
            this.loadData();
        },
        async reorder(property, orderType) {
            this.filter.orderByProperty = property;
            this.filter.orderType = orderType;
            this.loadData();
        },
        async search() {
            this.filter.page = 1;
            this.loadData();
        },
        async loadData() {
            const result = await UnitService.getList(this.filter);
            if (result.status) {
                this.errorMessages = null; 
            }
            else {
                this.errorMessages = result.errorMessages;
            }

            const data = result.data;
            this.pageNumber = data.pageNumber;
            this.perPage = data.perPage;
            this.totalPage = data.totalPage;
            this.totalRow = data.totalRow;
            this.data = data.data;
        },
        detailItem(item) {
            this.$router.push(`/master-unit/detail/${item.id}`);
        },
        editItem(item) {
            this.$router.push(`/master-unit/edit/${item.id}`);
        },
        async setDefaultItem() {
            try {
                this.$store.commit('progressCounterQueueIncrement');

                let status = false;
                if (this.popupModel.id !== undefined && this.popupModel.id !== null && this.popupModel.id !== 0) {
                    const result = await UnitService.setAsDefault(this.popupModel, this.popupModel.id);
                    status = result.status;
                    if (status) {
                        this.errorMessages = null;
                    }
                    else {
                        this.errorMessages = result.errorMessages;
                    }
                }
                else {
                    this.errorMessages = "Id not found.";
                }

                if (!status) return;

                this.$refs.setDefaultPopup.closePopup();
                this.$router.go();
            }
            finally {
                this.$store.commit('progressCounterQueueDecrement');
            }
        },
        openSetDefaultPopup(item) {
          this.popupModel = item
            this.$refs.setDefaultPopup.showPopup()
        },
        closeSetDefaultPopup() {
            this.$refs.setDefaultPopup.closePopup()
        },
        //,
        //async deleteItem(item) {
        //    if (confirm(`Are you sure want to delete vendor code ${item.code} with name ${item.name}`)) {
        //        const result = await UnitService.delete(item.id);
        //        if (result.status) {
        //            this.loadData();
        //        }
        //    }
        //}
    }
}
</script>

<style>
    
</style>